'use strict';

var cookies = require('js-cookie');
var config = require('../utils/config.js');

var todaysGolferPrefix = 'TodaysGolfer.';

var cookieNames = {
	isPaidTraffic: todaysGolferPrefix + 'IsPaidTraffic'
};

var cookieDurations = {};

module.exports = {
	deleteCookie: function (cookieName) {
		cookies.remove(cookieName, {
			domain: config.cookieDomain
		});
	},

	getCookie: function (cookieName) {
		var cookieData = cookies.get(cookieName);

		if (cookieData) {
			try {
				return JSON.parse(cookieData);
			}
			catch (e) {
				console.log("Cannot parse cookie as JSON " + e);
				return cookieData;
			}
		}

		return undefined;
	},

	setCookie: function (cookieName, cookieData, expires) {
		var options = {
			domain: config.cookieDomain
		};

		if (expires) {
			options.expires = expires;
		}

		if (typeof cookieData === "object") {
			cookieData = JSON.stringify(cookieData);
		}

		cookies.set(cookieName, cookieData, options);
	},
	
	names: cookieNames,
	durations: cookieDurations
};