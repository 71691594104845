'use strict';

var logger = require('./logger.js');

var tcfVersion = 2;

var eventNames = {
	uiShown: 'cmpuishown',
	userActionComplete: 'useractioncomplete',
	subsequentPageLoad: 'tcloaded'
};


var vendorConsents = {

	eventNames:eventNames,

	addTcfApiHandler: function (eventStatus, callback) {
		window.__tcfapi('addEventListener', tcfVersion, function (tcData, success) {
			if (!success) {
				logger.warn('Failed to add tcf api handler');
				return;
			}

			if (tcData.eventStatus === eventNames.uiShown && eventStatus === eventNames.uiShown) {
				callback();
			}

			if (tcData.eventStatus === eventNames.userActionComplete && eventStatus === eventNames.userActionComplete) {
				callback();
			}

			if (tcData.eventStatus === eventNames.subsequentPageLoad && eventStatus === eventNames.subsequentPageLoad) {
				window.__tcfapi('removeEventListener', tcfVersion, function (success) {
					if (success) {
						callback();
					}
				}, tcData.listenerId);
			}
		});
	},

	onRetrieveVendorAndPurposeConsents: function (successCallback, failureCallback) {
		var hasBauerConsent = false;
		var hasStoreAndAccessInfoConsent = false;
		var hasSelectPersonalisedAds = false;
		var hasCreatePersonalisedAdsProfile = false;

		window.__tcfapi('getCustomVendorConsents', tcfVersion, function (vendorConsents, success) {
			if (!success) {
				logger.warn('Failed to add bauer consent handler');
				return;
			}
			vendorConsents.consentedVendors.forEach(function (vendor) {
				if(vendor.name === 'Bauer Media Group') {
					hasBauerConsent = true;
				}
			});

			vendorConsents.consentedPurposes.forEach(function (purpose) {
				if (purpose.name === 'Store and/or access information on a device') {
					hasStoreAndAccessInfoConsent = true;
				}

				if (purpose.name === 'Use profiles to select personalised advertising') {
					hasSelectPersonalisedAds = true;
				}

				if (purpose.name === 'Create profiles for personalised advertising') {
					hasCreatePersonalisedAdsProfile = true;
				}
			});

			if (hasBauerConsent && hasStoreAndAccessInfoConsent && hasSelectPersonalisedAds && hasCreatePersonalisedAdsProfile) {
				successCallback();
			} else {
				failureCallback();
			}
		});
	}
};

module.exports = vendorConsents;