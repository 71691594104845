'use strict';

var $ = require('jquery');

require('jquery-unveil');

$.fn.lazyInterchange = function ()  {
	var images = this.each(function() {
		if($(this).attr('data-lazy-interchange')){
			$(this).attr('data-interchange', $(this).attr('data-lazy-interchange'))
				.removeAttr('data-lazy-interchange')
				.foundation('interchange', 'reflow');
		}
	});
	return images;
};

var self = module.exports = {
	init: function () {
		self.lazyLoad();
		self.lazyInterchangeLoad();
	},

	lazyLoad: function ($container, callback) {
		if (typeof $.fn.unveil !== 'undefined') {
			$('img[data-src],img[data-src-retina]', $container).unveil(100, callback);
			window.setTimeout(function () {
				// force scroll event so that images already in view being to load
				$(window).trigger('scroll');
				// force window resize event so that gridheights are recalculated after the images have been lazy-loaded
				$(window).trigger('resize');
			}, 100);
		}
	},

	interchange: function ($container) {
		if (typeof $.fn.foundation !== 'undefined') {
			$container = $container || $(document);
			$container.foundation('interchange', 'reflow');
		}
	},

	lazyInterchangeLoad: function($container) {
		$container = $container || $(document);
		//When using lazyInterchange, the data-src should be set to a transparent/placeholder image
		//which will be replaced, when it loads, with the foundation interchange image set.
		$('img[data-lazy-interchange]', $container).unveil(100, function() {
			$(this).lazyInterchange();
		});
	},
};