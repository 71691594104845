'use strict';

var cookie = require('./cookie');

module.exports = {
	isPaidTraffic: function() {
		var result = cookie.getCookie(cookie.names.isPaidTraffic);
		if (typeof(result) !== 'undefined' && result !== null) {
			return result;
		}

		var queryStringParams = new URLSearchParams(window.location.search);
		var utmMedium = queryStringParams.get('utm_medium');
		var utmSource = queryStringParams.get('utm_source');

		result = utmMedium !== null &&
			utmSource !== null &&
			utmMedium.toLowerCase() === 'cpc' &&
			utmSource.toLowerCase() !== 'google';

		cookie.setCookie(cookie.names.isPaidTraffic, result);
		return result;
	}
};